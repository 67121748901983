import menu from './menu'
import meta from './meta'
import footer from './footer'

const data = {
  menu,
  meta,
  components: [
    {
      name: 'Wrapper',
      components: [
        {
          name: 'Breadcrumb',
          props: {
            urls: [],
          }
        },
        {
          name: 'Speaker',
          props: {
            name: 'Juan Baixeras',
            slug: 'juan-baixeras',
            position: 6,
            company: 'Audible (Amazon)',
            url: 'https://www.audible.com',
            image: '/speakers/2020/juan-baixeras.jpg',
            text: {
              es: `
              <p>Vicepresidente y Country Manager de Audible España (Amazon), la compañía de audio (audiolibros , podcast y audioshows) de Amazon. Con más de 20 años de experiencia en la industria de los contenidos, medios de comunicación y publicidad (Audible / Amazon, PlayGround, Vía Digital, QuieroTV), Retail y Editorial (Grupo Planeta / Casa del Libro, TP&amp;Asociados), videojuegos y empresas de entretenimiento (Pocoyó, 2bTube), he desempeñado roles de dirección general, dirección comercial, E-Commerce y marketing.</p><p>Como empresario he participado en el lanzamiento de Start Ups tecnológicas (Little Smart Planet, Sentisis) poniendo foco en alinear estrategias de negocio y producto con tecnología, para lograr la transformación digital y organizacional de las empresas.</p>
              `,
              ca: `
              <p>Vicepresident i Country Manager d'Audible Espanya (Amazon), la companyia d'àudio (audiollibres , podcast i audioshows) de Amazon. Amb més de 20 anys d'experiència en la indústria dels continguts, mitjans de comunicació i publicitat (Audible / Amazon, PlayGround, Via Digital, QuieroTV), Retail i Editorial (Grupo Planeta / Casa del Llibre, TP& amp Associats), videojocs i empreses d'entreteniment (Pocoyó, 2bTube), he exercit rols de direcció general, direcció comercial, E-Commerce i màrqueting.</p><p>Com a empresari he participat en el llançament de Start Ups tecnològiques (Little Smart Planet, Sentisis) posant focus a alinear estratègies de negoci i producte amb tecnologia, per a aconseguir la transformació digital i organitzacional de les empreses.</p>
              `,
              en: `
              <p>Vice President and Country Manager of Audible Spain (Amazon), the audio company (audiobooks, podcasts and audioshows) of Amazon. With more than 20 years of experience in the content, media and advertising industry (Audible / Amazon, PlayGround, Vía Digital, QuieroTV), Retail and Editorial (Grupo Planeta / Casa del Libro, TP & Associates), video games and Entertainment companies (Pocoyo, 2bTube), I have played general management, business management, E-Commerce and marketing roles.</p><p>As an entrepreneur I have participated in the launch of technological Start Ups (Little Smart Planet, Sentisis) focusing on aligning business and product strategies with technology, to achieve the digital and organizational transformation of companies.</p>
              `,
            },
            role: {
              es: `VP Country Manager Audible (Amazon)`,
              ca: `VP Country Manager Audible Espanya (Amazon)`,
              en: `VP Country Manager, Audible (an Amazon Company)`,
            },
          }
        },
        {
          name: 'YouTube',
          props: {
            id: '1soxCeGPG4c',
          }
        },
        footer,
      ]
    }
  ],
}

export default data